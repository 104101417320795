import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setShowAuthenticationPopup } from "../../redux/slices/globalSlice";
import "./Booking.css";
import { firestore, auth } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import Loader from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {sendMail} from '../../services/mail';
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

const Booking = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const setStartDateTime = (date) => {
    const clone = new Date(date);
    const startDate = clone.setHours(14, 0, 0, 0);
    return new Date(startDate);
  };
  const setEndDateTime = (date) => {
    const clone = new Date(date);
    const startDate = clone.setHours(12, 0, 0, 0);
    return new Date(startDate);
  };
  const roomState = useSelector((state) => state.room);
  const [showLoader, setShowLoader] = useState(false);
  const [available, setAvailable] = useState(true);
  const [startDate, setStartDate] = useState(setStartDateTime(new Date()));
  const [endDate, setEndDate] = useState(
    setEndDateTime(
      new Date(
        new Date().setMonth(
          new Date().getMonth() + roomState.currentRoom.minimum_rental_time
        )
      )
    )
  );
  const [price, setPrice] = useState(0);
  const [error, setError] = useState("");
  const [validation, setValidation] = useState(false);

  const selectStartDate = (start) => {
    setAvailable(false);
    const startDate = setStartDateTime(start);
    if (startDate < new Date()) {
      setError(t("BOOKING_START_DATE_ERROR"));
    } else {
      setError("");
      const cloneStartDate = new Date(startDate);
      const endDate = new Date(
        cloneStartDate.setMonth(
          startDate.getMonth() + roomState.currentRoom.minimum_rental_time
        )
      );
      setEndDate(setEndDateTime(endDate));
      setStartDate(startDate);
      priceCalculation(startDate, endDate);
      checkAvailability(startDate, endDate);
    }
  };
  const selectEndDate = (end) => {
    setAvailable(false);
    const endDate = setEndDateTime(end);
    const cloneStartDate = new Date(startDate);
    const checkMinimumDate = new Date(
      cloneStartDate.setMonth(
        startDate.getMonth() + roomState.currentRoom.minimum_rental_time
      )
    );
    if (endDate < checkMinimumDate) {
      setError(
        t("BOOKING_END_DATE_ERROR")
      );
    } else {
      setError("");
      setEndDate(endDate);
      priceCalculation(startDate, endDate);
      checkAvailability(startDate, endDate);
    }
  };

  const diffInMonths = (startDate, endDate) => {
    var timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    return Math.round(timeDiff / (2e3 * 3600 * 365.25));
  };

  const diffInDays = (startDate, endDate) => {
    return Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
  };

  const priceCalculation = (startDate, endDate) => {
    const montlyPrice = roomState.currentRoom.monthly_price;
    const dailyPrice = roomState.currentRoom.daily_price;
    const months = diffInMonths(startDate, endDate);
    const cloneStartDate = new Date(startDate);
    const checkDays = cloneStartDate.setMonth(startDate.getMonth() + months);
    const days = diffInDays(checkDays, endDate);
    const price = montlyPrice * months + dailyPrice * days;
    setPrice(price);
  };

  const checkAvailability = (startDate, endDate) => {
    firestore
      .collection("Bookings")
      .where("room", "==", roomState.currentRoom.id)
      .onSnapshot((result) => {
        setValidation(false);
        setShowLoader(true);
        setAvailable(true);
        setError("");
        result.forEach((item) => {
          let booking = item.data();
          if (startDate <= booking.endDate.toDate()) {
            setAvailable(false);
            setError(t("BOOKING_DATE_ERROR"));
          }
        });
        setShowLoader(false);
      });
  };

  const book = (startDate, endDate) => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setShowLoader(true);
        setError("");
        firestore
          .collection("Bookings")
          .doc(uuidv4())
          .set({
            room: roomState.currentRoom.id,
            startDate: startDate,
            endDate: endDate,
            user: user.email,
            status: "pending",
            price: price,
          })
          .then(() => {
            sendMail(roomState.currentRoom.id, startDate, endDate, price, "booking-creation", i18n.language);
            sendMail(roomState.currentRoom.id, startDate, endDate, price, "booking-admin");
            setValidation(true);
          })
          .catch(() => {
            setError(t("GLOBAL_ERROR"));
          })
          .finally(() => {
            setShowLoader(false);
          });
      } else {
        dispatch(setShowAuthenticationPopup(true));
      }
    })
  };

  useEffect(() => {
    priceCalculation(startDate, endDate);
    checkAvailability(startDate, endDate);
  }, []);

  return (
    <div className="col-md-12 col-xl-4 order-1 order-xl-2">
      <div className="sidebar-booking sticky-column">
        <div className="sidebar-booking__priceWrap">
          <div className="priceWrap-price">
            {roomState.currentRoom.daily_price !== 0 && <span> {t("ROOM_DAILY_PRICE")}</span>}
            {roomState.currentRoom.daily_price !== 0 && roomState.currentRoom.daily_price + "€"}
            
            <br />
            <span>{t("ROOM_MONTHLY_PRICE")}</span>{" "}
            {roomState.currentRoom.monthly_price}€
          </div>
        </div>
        <form className="sidebar-booking__wrap">
          {/* <!-- Dates --> */}
          <div className="form-group">
            <label className="label" htmlFor="check-in">
              {t("BOOKING_DATE")}
            </label>
            <div className="form-dual form-dual--mobile">
              <div className="form-dual__left">
                <DatePicker
                  className="inputText inputText__icon readonly js-datepicker"
                  dateFormat="dd-MM-yyyy"
                  selected={startDate}
                  onChange={(date) => selectStartDate(date)}
                  customInput={
                    <Form.Control
                      className="inputText__icon"
                      value={startDate}
                    />
                  }
                />
                <span className="input-icon icon-calendar"></span>
              </div>
              <div className="form-dual__right">
                <DatePicker
                  className="inputText inputText__icon readonly js-datepicker"
                  dateFormat="dd-MM-yyyy"
                  selected={endDate}
                  onChange={(date) => selectEndDate(date)}
                  customInput={
                    <Form.Control className="inputText__icon" value={endDate} />
                  }
                />
                <span className="input-icon icon-calendar"></span>
              </div>
            </div>
          </div>

          <div className="row">
            {/* <!-- Persons --> */}
            {/* <div className="col-12 col-sm-12 form-group">
              <label className="label" for="person-adult">
                {t("BOOKING_PRICE")}
              </label>
              <Form.Control readOnly value={price} />
            </div> */}

            <div className="col-12 mt-1">
              <Button
                className="btn btn__medium w-100"
                style={{ width: "150px" }}
                variant="primary"
                type="button"
                disabled={!available}
                onClick={() => {
                  book(startDate, endDate);
                }}
              >
                {showLoader && (
                  <Loader
                    type="ThreeDots"
                    color="#FFFFFF"
                    height={20}
                    width={50}
                  />
                )}
                {!showLoader && <span>{t("BOOKING_SUBMIT")}</span>}
              </Button>
            </div>
            <span className="sidebar-booking__note">{t("BOOKING_INFO")}</span>
          </div>
          {error && !validation && (
            <Alert variant="danger">
              <p>{error}</p>
            </Alert>
          )}
          {validation && (
            <Alert variant="success">{t("BOOKING_VALIDATION")}</Alert>
          )}
        </form>
      </div>
    </div>
  );
};

export default Booking;
