import { createSlice } from "@reduxjs/toolkit";
import { auth, firestore } from "../../firebase";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    bookings: [],
    isMobile: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
    setMobileStatus: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

export const { setUser, setBookings, setMobileStatus } = userSlice.actions;

export const getUser = () => (dispatch) => {
  firestore
    .collection("Users")
    .doc(auth.currentUser.email)
    .get()
    .then((item) => {
      if (item.exists) {
        dispatch(setUser(item.data()));
      } else {
        dispatch(setUser(null));
      }
    });
};

export const login = (email, password) => async (dispatch) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
    dispatch(getUser());
    return "";
  } catch (err) {
    return err.code;
  }
};

export const registration = (
  email,
  password,
  phoneNumber,
  firstName,
  lastName
) => async (dispatch) => {
  try {
    await auth.createUserWithEmailAndPassword(email, password);
    await firestore.collection("Users").doc(email).set({
      email: email,
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      admin: false,
    });
    dispatch(getUser());
    return "";
  } catch (err) {
    return err.code;
  }
};

export const resetPassword = (email) => async (dispatch) => {
  try {
    await auth.sendPasswordResetEmail(email);
    return "";
  } catch (err) {
    return err.code;
  }
};

export const logout = () => async (dispatch) => {
  try {
    await auth.signOut();
    dispatch(setUser(null));
    return "";
  } catch (err) {
    return err.code;
  }
};

export const getBookings = () => (dispatch) => {
  firestore
    .collection("Bookings")
    .where("user", "==", auth.currentUser.email)
    .orderBy("startDate", "desc")
    .onSnapshot((result) => {
      let bookings = [];
      result.docs.map((doc) => {
        let booking = doc.data();
        bookings.push({
          id: doc.id,
          room: booking.room,
          startDate: booking.startDate.toDate().getTime(),
          endDate: booking.endDate.toDate().getTime(),
          status: booking.status,
          price: booking.price,
        });
      });
      dispatch(setBookings(bookings));
    });
};



export const userState = (state) => state.user;

export default userSlice.reducer;
