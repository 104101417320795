import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    showAuthenticationPopup: false
  },
  reducers: {
    setShowAuthenticationPopup: (state, action) => {
      state.showAuthenticationPopup = action.payload;
    }
  },
});

export const { setShowAuthenticationPopup } = globalSlice.actions;

export const globalState = (state) => state.global;

export default globalSlice.reducer;
