import React, { useState, useRef } from "react";
import "./SectionPlace.scss";
import { useTranslation } from "react-i18next";
import Fade from "@successtar/react-reveal/Fade";
import Swiper from "react-id-swiper";
import { useLocation } from "react-router-dom";
import imageAbout1 from "../../assets/images/brugmann/image_slider_home_01.jpg";
import imageAbout2 from "../../assets/images/brugmann/image_slider_home_02.jpg";
import imageAbout3 from "../../assets/images/brugmann/image_slider_home_03.jpg";
import imageAbout4 from "../../assets/images/brugmann/image_slider_home_04.jpg";
import imageAboutLatins1 from "../../assets/images/latins/LATINS_TERRASSE-COMMUNS.jpg";
import imageAboutLatins2 from "../../assets/images/latins/LATINS_cuisine-3.jpg";
import imageAboutLatins3 from "../../assets/images/latins/LATINS_COMMUNS.jpg";
import imageAboutLatins4 from "../../assets/images/latins/LATINS_HALL-ENTREE.jpg";
import imageAboutUniversity1 from "../../assets/images/university/CUISINE_COMMUNS.jpg";
import imageAboutUniversity2 from "../../assets/images/university/SALLE_TELE.jpg";
import imageAboutUniversity3 from "../../assets/images/university/SALLE_LECTURE_COMMUNE_2.jpg";
import imageAboutUniversity4 from "../../assets/images/university/TERRASSE_ET_JARDIN_COMMUNS_(7).jpg";
import imageAboutWashington1 from "../../assets/images/washington/washington_sauna.jpg";
import imageAboutWashington2 from "../../assets/images/washington/washington_commun.jpg";
import imageAboutWashington3 from "../../assets/images/washington/washington_int1.jpg";
import imageAboutWashington4 from "../../assets/images/washington/washington_jardin.jpg";
import imageAboutWashington5 from "../../assets/images/washington/washington_sport.jpg";
import imageAboutChurchill1 from "../../assets/images/churchill/community_room.jpg";
import imageAboutChurchill2 from "../../assets/images/churchill/communs.jpg";
import imageAboutChurchill3 from "../../assets/images/churchill/communs3.jpg";
import imageAboutChurchill4 from "../../assets/images/churchill/buanderie.jpg";
import imageAboutChurchill5 from "../../assets/images/churchill/babyfoot.jpg";
import imageAboutChurchill6 from "../../assets/images/churchill/cuisine.jpg";
import imageAboutChurchill7 from "../../assets/images/churchill/cuisne_salle_a_manger.jpg";
import imageAboutChurchill8 from "../../assets/images/churchill/hall_entree.jpg";
import imageAboutChurchill9 from "../../assets/images/churchill/jardin_terrasse.jpg";

const Contact = () => {
  const location = useLocation();
  const swiperRef = useRef(null);
  const { t, i18n } = useTranslation();
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };


  const swiperParams = {
    loop: true,
    speed: 900,
    slidesPerView: "auto",
    spaceBetween: 140,
    centeredSlides: true,
    grabCursor: true,
    mousewheel: true,
    breakpoints: {
      0: {
        spaceBetween: 0,
      },
      580: {
        spaceBetween: 40,
      },
      768: {
        spaceBetween: 40,
      },
      990: {
        spaceBetween: 80,
      },
      1200: {
        spaceBetween: 80,
      },
      1500: {
        spaceBetween: 100,
      },
    },
  };

  
  return (
    <section className="section" id="place">
      <div className="container">
        <div className="row">
          <Fade bottom>
            {location.pathname == "/fox-brugmann" && (
              <div className="slider-about col-fullwidth swiper-container">
                <div className="swiper-wrapper">
                  <Swiper {...swiperParams} ref={swiperRef}>
                    <div>
                      <img alt="poster" width="100%" src={imageAbout1} />
                    </div>
                    <div>
                      <img alt="poster" width="100%" src={imageAbout2} />
                    </div>
                    <div>
                      <img alt="poster" width="100%" src={imageAbout3} />
                    </div>
                    <div>
                      <img alt="poster" width="100%" src={imageAbout4} />
                    </div>
                  </Swiper>
                </div>

                {/* <!-- Navigation --> */}
                <div className="swiper-control-center">
                  <div className="swiper-control swiper-control--bottom">
                    <div className="slash">
                      <div
                        onClick={goPrev}
                        className="swiper-button-next swiper-button-square"
                      >
                        <i className="icon-chevron-left"></i>
                      </div>
                      <div
                        onClick={goNext}
                        className="swiper-button-prev swiper-button-square"
                      >
                        <i className="icon-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fade>
          <Fade bottom>
            {location.pathname == "/fox-latins" && (
              <div className="slider-about col-fullwidth swiper-container">
                <div className="swiper-wrapper">
                  <Swiper {...swiperParams} ref={swiperRef}>
                    <div>
                      <img alt="poster" width="100%" src={imageAboutLatins1} />
                    </div>
                    <div>
                      <img alt="poster" width="100%" src={imageAboutLatins2} />
                    </div>
                    <div>
                      <img alt="poster" width="100%" src={imageAboutLatins3} />
                    </div>
                    <div>
                      <img alt="poster" width="100%" src={imageAboutLatins4} />
                    </div>
                  </Swiper>
                </div>

                {/* <!-- Navigation --> */}
                <div className="swiper-control-center">
                  <div className="swiper-control swiper-control--bottom">
                    <div className="slash">
                      <div
                        onClick={goPrev}
                        className="swiper-button-next swiper-button-square"
                      >
                        <i className="icon-chevron-left"></i>
                      </div>
                      <div
                        onClick={goNext}
                        className="swiper-button-prev swiper-button-square"
                      >
                        <i className="icon-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fade>
          <Fade bottom>
            {location.pathname == "/fox-university" && (
              <div className="slider-about col-fullwidth swiper-container">
                <div className="swiper-wrapper">
                  <Swiper {...swiperParams} ref={swiperRef}>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutUniversity1}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutUniversity2}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutUniversity3}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutUniversity4}
                      />
                    </div>
                  </Swiper>
                </div>

                {/* <!-- Navigation --> */}
                <div className="swiper-control-center">
                  <div className="swiper-control swiper-control--bottom">
                    <div className="slash">
                      <div
                        onClick={goPrev}
                        className="swiper-button-next swiper-button-square"
                      >
                        <i className="icon-chevron-left"></i>
                      </div>
                      <div
                        onClick={goNext}
                        className="swiper-button-prev swiper-button-square"
                      >
                        <i className="icon-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fade>
          <Fade bottom>
            {location.pathname == "/fox-washington" && (
              <div className="slider-about col-fullwidth swiper-container">
                <div className="swiper-wrapper">
                  <Swiper {...swiperParams} ref={swiperRef}>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutWashington1}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutWashington2}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutWashington3}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutWashington4}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutWashington5}
                      />
                    </div>
                  </Swiper>
                </div>

                {/* <!-- Navigation --> */}
                <div className="swiper-control-center">
                  <div className="swiper-control swiper-control--bottom">
                    <div className="slash">
                      <div
                        onClick={goPrev}
                        className="swiper-button-next swiper-button-square"
                      >
                        <i className="icon-chevron-left"></i>
                      </div>
                      <div
                        onClick={goNext}
                        className="swiper-button-prev swiper-button-square"
                      >
                        <i className="icon-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fade>
          <Fade bottom>
            {location.pathname == "/fox-churchill" && (
              <div className="slider-about col-fullwidth swiper-container">
                <div className="swiper-wrapper">
                  <Swiper {...swiperParams} ref={swiperRef}>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutChurchill1}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutChurchill2}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutChurchill3}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutChurchill4}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutChurchill5}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutChurchill6}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutChurchill7}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutChurchill8}
                      />
                    </div>
                    <div>
                      <img
                        alt="poster"
                        width="100%"
                        src={imageAboutChurchill9}
                      />
                    </div>
                  </Swiper>
                </div>

                {/* <!-- Navigation --> */}
                <div className="swiper-control-center">
                  <div className="swiper-control swiper-control--bottom">
                    <div className="slash">
                      <div
                        onClick={goPrev}
                        className="swiper-button-next swiper-button-square"
                      >
                        <i className="icon-chevron-left"></i>
                      </div>
                      <div
                        onClick={goNext}
                        className="swiper-button-prev swiper-button-square"
                      >
                        <i className="icon-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Contact;
