import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setShowAuthenticationPopup } from "../../redux/slices/globalSlice";
import { registration } from "../../redux/slices/userSlice";
import Loader from "react-loader-spinner";
import "./Registration.css";
import { useHistory, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";

const Registration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const closeAuthenticationPopup = () => {
    dispatch(setShowAuthenticationPopup(false));
    if (location.pathname === "/dashboard") {
      history.push("/");
    }
  }
  const submitRegistrationForm = () => {
    setErrors([]);
    let errors = [];
    if (
      email.trim() === "" ||
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      ) === false
    ) {
      errors.push(t("AUTH_EMAIL_ERROR"));
    }
    if (
      phoneNumber.trim() === "" ||
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
        phoneNumber
      ) === false
    ) {
      errors.push(t("AUTH_PHONE_NUMBER_ERROR"));
    }
    if (firstName.trim() === "") {
      errors.push(t("AUTH_FIRST_NAME_ERROR"));
    }
    if (lastName.trim() === "") {
      errors.push(t("AUTH_LAST_NAME_ERROR"));
    }
    if (password.trim() === "" || password.trim().length < 8) {
      errors.push(t("AUTH_PASSWORD_ERROR"));
    }
    if (password !== passwordConfirmation) {
      errors.push(t("AUTH_PASSWORD_CONFIRMATION_ERROR"));
    }
    if (errors.length === 0) {
      setShowLoader(true);
      dispatch(
        registration(email, password, phoneNumber, firstName, lastName)
      ).then((result) => {
        setShowLoader(false);
        if (result === "") {
          dispatch(setShowAuthenticationPopup(false));
        } else {
          switch (result) {
            case 'auth/email-already-exists':
              errors.push(t("AUTH_EMAIL_ALREADY_EXIST"));
              break;
            case 'auth/invalid-email':
              errors.push(t("AUTH_EMAIL_ERROR"));
              break;
            default:
              errors.push(t("GLOBAL_ERROR"));
              break;
          }
          errors.push(result);
          setErrors(errors);
        }
      });
    } else {
      setErrors(errors);
    }
  };
  return (
    <div className="containter">
      <h1>{t("AUTH_REGISTRATION")}</h1>
      <Form>
        <Form.Group>
          <Form.Label>{t("AUTH_EMAIL")}</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("AUTH_PHONE_NUMBER")}</Form.Label>
          <Form.Control
            type="text"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("AUTH_FIRST_NAME")}</Form.Label>
          <Form.Control
            type="text"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("AUTH_LAST_NAME")}</Form.Label>
          <Form.Control
            type="text"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("AUTH_PASSWORD")}</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("AUTH_PASSWORD_CONFIRMATION")}</Form.Label>
          <Form.Control
            type="password"
            value={passwordConfirmation}
            onChange={(event) => setPasswordConfirmation(event.target.value)}
          />
        </Form.Group>
        {errors.length > 0 && (
          <Alert variant="danger">
            {errors.map((error) => {
              return <p>{error}</p>;
            })}
          </Alert>
        )}
        <div className="row">
          <div className="col-sm">
            <Button
              style={{ width: "150px" }}
              variant="primary"
              type="button"
              onClick={() => {
                submitRegistrationForm();
              }}
            >
              {showLoader && (
                <Loader
                  type="ThreeDots"
                  color="#FFFFFF"
                  height={20}
                  width={50}
                />
              )}
              {!showLoader && <span>{t("AUTH_REGISTRATION_SUBMIT")}</span>}
            </Button>
          </div>
          <div className="col-sm">
            <Button
              className="float-right"
              style={{ width: "150px" }}
              variant="secondary"
              type="button"
              onClick={() => {
                closeAuthenticationPopup();
              }}
            >
              {t("GLOBAL_CLOSE")}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Registration;
