import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./BookingList.css";
import { firestore } from "../../firebase";
import { getBookings } from "../../redux/slices/userSlice";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaTrashAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../helper";

const BookingList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [bookingToDelete, setBookingToDelete] = useState("");
  const userState = useSelector((state) => state.user);
  const checkDelete = (booking) => {
    let now = new Date();
    let checkDate = new Date(booking.startDate);
    checkDate.setDate(checkDate.getDate() - 7);
    if (new Date(checkDate) <= now) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    dispatch(getBookings());
  }, []);
  return (
    <div className="containter">
      <Modal show={bookingToDelete !== ""} onHide={() => setBookingToDelete("")}>
        <Modal.Body>
          <p>{t("DASHBOARD_BOOKING_DELETE")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setBookingToDelete("");
            }}
          >
            {t("GLOBAL_CLOSE")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              firestore.collection("Bookings").doc(bookingToDelete).delete();
              setBookingToDelete("");
            }}
          >
            {t("GLOBAL_CONFIRM")}
          </Button>
        </Modal.Footer>
      </Modal>
      {userState.bookings.length === 0 && <p>{t("DASHBOARD_BOOKING_EMPTY")}</p>}
      {userState.bookings.length > 0 && (
        <Table responsive>
          <thead>
            <tr>
              <th></th>
              <th>{t("BOOKING_START_DATE")}</th>
              <th>{t("BBOKING_END_DATE")}</th>
              <th>{t("BOKING_ROOM")}</th>
              <th>{t("BOOKING_PRICE")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userState.bookings.map((booking) => {
              return (
                <tr key={booking.id}>
                  <td>
                    {booking.status === "blocked" && (
                      <span className="badge badge-secondary">{t("BOOKING_STATUS_BLOCKED")}</span>
                    )}
                    {booking.status === "pending" && (
                      <span className="badge badge-warning">{t("BOOKING_STATUS_PENDING")}</span>
                    )}
                    {booking.status === "validate" && (
                      <span className="badge badge-success">{t("BOOKING_STATUS_VALIDATE")}</span>
                    )}
                  </td>
                  <td>{formatDate(booking.startDate)}</td>
                  <td>{formatDate(booking.endDate)}</td>
                  <td>{booking.room}</td>
                  <td>{booking.price} €</td>
                  <td>
                    {checkDelete(booking) && (
                      <FaTrashAlt
                        onClick={() => {
                          setBookingToDelete(booking.id);
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default BookingList;
