import React from "react";
import "./ContactInfo.scss";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="bottom-panel height-md-auto">
      <div className="bottom-panel__wrap">
        <div className="row h-100 align-items-center">
          <div className="col-12">
            <div className="row ">
              <div className="col-12 col-md-6 col-lg-4 mb-2 mb-sm-4 mb-md-0">
                <div className="bottom-panel-title">
                  {t("CONTACTINFO_QUESTION")}
                </div>
                <span className="bottom-panel-info">
                  +32 2 213 42 02 - +32 2 213 42 04
                </span>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-2 mb-sm4 mb-md-0">
                <div className="bottom-panel-title">
                  {t("CONTACTINFO_EMAIL")}
                </div>
                <span className="bottom-panel-info">
                  info@foxcoliving.be
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
