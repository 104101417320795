import React from "react";
import "./Location.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import image1 from "../../assets/images/brugmann/about_image_01.jpg";
import image2 from "../../assets/images/brugmann/about_image_02.jpg";
import image3 from "../../assets/images/latins/LATINS-facade-arriere.jpg";
import image4 from "../../assets/images/latins/LATINS-facade-avant.jpg";
import image5 from "../../assets/images/university/TERRASSE_ET_JARDIN_COMMUNS_(6).jpg";
import image6 from "../../assets/images/university/FACADE_009_imaginyou(4).jpg";
import image7 from "../../assets/images/washington/washington_arriere.jpg";
import image8 from "../../assets/images/washington/facade_washington.jpg";
import image9 from "../../assets/images/churchill/facade_avant.jpg";
import image10 from "../../assets/images/churchill/jardin.jpg";
import Fade from "@successtar/react-reveal/Fade";
import { HashLink } from "react-router-hash-link";

const Location = () => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <section id="localisation" className="section section-gray">
      <div className="container">
        {/* <div className="row"> */}
        {/* <div className="col-12">
            <Fade>
              <h1 className="title title--h1 js-lines">
                {t("LOCATION_TITLE")}
              </h1>
            </Fade>
          </div>
          <Fade>
            <div className="col-12 col-lg-6 text-left text-sm-justify">
              <p className="mr-0 mr-lg-4 paragraph js-scroll-show">
                {t("LOCATION_CONTENT_1")}
              </p>
            </div>
            <div className="col-12 col-lg-6 text-left text-sm-justify">
              <p className="ml-0 ml-lg-4 paragraph js-scroll-show">
                {t("LOCATION_CONTENT_2")}
              </p>
            </div>
          </Fade> */}
        <Fade>
          {location.pathname === "/fox-brugmann" && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap mr-0 mr-lg-4">
                  <HashLink
                    smooth
                    to="/fox-brugmann#map"
                    className="about-link"
                  >
                    {t("LOCATION_CONTENT_LINK_MAP")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-portrait"
                    src={image1}
                    alt="about"
                  />
                </figure>
              </div>
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap ml-0 ml-lg-4">
                  <HashLink smooth to="/roomlist" className="about-link">
                    {t("LOCATION_CONTENT_LINK_SEEROOM")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-landscape"
                    src={image2}
                    alt="about"
                  />
                </figure>
              </div>
            </div>
          )}
        </Fade>
        <Fade>
          {location.pathname === "/fox-latins" && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap mr-0 mr-lg-4">
                  <HashLink smooth to="/fox-latins#map" className="about-link">
                    {t("LOCATION_CONTENT_LINK_MAP")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-portrait"
                    src={image3}
                    alt="about"
                  />
                </figure>
              </div>
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap ml-0 ml-lg-4">
                  <HashLink
                    smooth
                    to="/roomlist#foxlatins"
                    className="about-link"
                  >
                    {t("LOCATION_CONTENT_LINK_SEEROOM")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-landscape"
                    src={image4}
                    alt="about"
                  />
                </figure>
              </div>
            </div>
          )}
        </Fade>
        <Fade>
          {location.pathname === "/fox-university" && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap mr-0 mr-lg-4">
                  <HashLink
                    smooth
                    to="/fox-university#map"
                    className="about-link"
                  >
                    {t("LOCATION_CONTENT_LINK_MAP")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-portrait"
                    src={image5}
                    alt="about"
                  />
                </figure>
              </div>
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap ml-0 ml-lg-4">
                  <HashLink
                    smooth
                    to="/roomlist#university"
                    className="about-link"
                  >
                    {t("LOCATION_CONTENT_LINK_SEEROOM")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-landscape"
                    src={image6}
                    alt="about"
                  />
                </figure>
              </div>
            </div>
          )}
        </Fade>
        <Fade>
          {location.pathname === "/fox-washington" && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap mr-0 mr-lg-4">
                  <HashLink
                    smooth
                    to="/fox-washington#map"
                    className="about-link"
                  >
                    {t("LOCATION_CONTENT_LINK_MAP")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-portrait"
                    src={image7}
                    alt="about"
                  />
                </figure>
              </div>
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap ml-0 ml-lg-4">
                  <HashLink
                    smooth
                    to="/roomlist#washington"
                    className="about-link"
                  >
                    {t("LOCATION_CONTENT_LINK_SEEROOM")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-landscape"
                    src={image8}
                    alt="about"
                  />
                </figure>
              </div>
            </div>
          )}
        </Fade>
        <Fade>
          {location.pathname === "/fox-churchill" && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap mr-0 mr-lg-4">
                  <HashLink
                    smooth
                    to="/fox-churchill#map"
                    className="about-link"
                  >
                    {t("LOCATION_CONTENT_LINK_MAP")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-portrait"
                    src={image9}
                    alt="about"
                  />
                </figure>
              </div>
              <div className="col-12 col-lg-6">
                <figure className="about-image-wrap ml-0 ml-lg-4">
                  <HashLink
                    smooth
                    to="/roomlist#churchill"
                    className="about-link"
                  >
                    {t("LOCATION_CONTENT_LINK_SEEROOM")}
                    <i className="icon-arrow-special"></i>
                  </HashLink>
                  <img
                    className="cover about-image-landscape"
                    src={image10}
                    alt="about"
                  />
                </figure>
              </div>
            </div>
          )}
        </Fade>
      </div>
      {/* </div> */}
    </section>
  );
};

export default Location;
