import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
const config = {
  apiKey: "AIzaSyAUbmuBU4H9F3FJuHNm46-Ttl0R7q6v1OE",
  authDomain: "fox-brugmann.firebaseapp.com",
  databaseURL: "https://fox-brugmann.firebaseio.com",
  projectId: "fox-brugmann",
  storageBucket: "fox-brugmann.appspot.com",
  messagingSenderId: "352389793030",
  appId: "1:352389793030:web:67be723a2990cc806b4a87",
  measurementId: "G-C2436GHSFJ",
};

firebase.initializeApp(config);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export default firebase;
