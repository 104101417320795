import React from "react";
import "./Footer.css";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  return (
    <footer className="footer">
      <div className="footer__left">
        <ul className="footer__info">
          <li>
            © 2020 <a href="www.firstweb.be">Firstweb.be</a>
          </li>
          {/* <!-- <li><a href="text-page.html">Terms & Conditions</a></li>
                    <li><a href="text-page.html">Privacy Policy</a></li> --> */}
        </ul>
      </div>
      <ul className="footer__social">
        <li>
          {location.pathname == "/fox-brugmann" && (
            <a
              href="https://www.facebook.com/permalink.php?id=116393226875729&story_fbid=121774383004280"
              target="_blank"
              className="social-link"
            >
              <i className="icon-facebook-alt"></i>
            </a>
          )}
          {location.pathname == "/fox-latins" && (
            <a
              href="https://www.facebook.com/Fox-Latins-109842114818464"
              target="_blank"
              className="social-link"
            >
              <i className="icon-facebook-alt"></i>
            </a>
          )}
        </li>
        <li>
          <a
            href="https://www.instagram.com/fox_coliving/"
            target="_blank"
            className="social-link"
          >
            <i className="icon-instagram"></i>
          </a>
        </li>
        {/* <!-- <li><a href="#" className="social-link"><i className="icon-twitter-alt"></i></a></li>
                <li><a href="#" className="social-link"><i className="icon-instagram"></i></a></li> --> */}
      </ul>
    </footer>
  );
};

export default Footer;
