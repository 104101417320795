import React, {useRef, useEffect} from "react";
import "./Intro.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import imageIntro1 from "../../assets/images/header.jpg";
import imageIntro2 from "../../assets/images/brugmann/image_header_01.jpg";
import imageIntro3 from "../../assets/images/latins/LATINS_COMMUNS-AVEC-VUE-TERRASSE.jpg";
import imageIntro4 from "../../assets/images/university/SALLE_A_MANGER_COMMUN.jpg";
import imageIntro5 from "../../assets/images/washington/washington_commun.jpg";
import imageIntro6 from "../../assets/images/churchill/cuisine_HR.jpg";
import { animated, useSpring } from "react-spring";
import Fade from "@successtar/react-reveal/Fade";
import { HashLink } from "react-router-hash-link";
import LogoHeader from "../img/LogoHeader";

const Header = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const calc = (o) => `translateY(${o * 0.1}px)`;

  const HeaderImage = () => {
    const ref = useRef();
    const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

    const handleScroll = () => {
      const posY = ref.current.getBoundingClientRect().top;
      const offset = window.pageYOffset - posY;
      set({ offset });
    };

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });

    return (
      <div ref={ref} className="overlay intro__bg js-image">
        <animated.div
          style={{
            backgroundImage:
              location.pathname == "/fox-brugmann"
                ? `url(${imageIntro2})`
                : location.pathname == "/fox-latins"
                ? `url(${imageIntro3})`
                : location.pathname == "/fox-university"
                ? `url(${imageIntro4})`
                : location.pathname == "/fox-washington"
                ? `url(${imageIntro5})`
                : location.pathname == "/fox-churchill"
                ? `url(${imageIntro6})`
                : "",
            backgroundSize: "cover",
            backgroundPosition: location.pathname == "/fox-churchill" ? "bottom" : "center",
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            transform: offset.interpolate(calc),
          }}
        />
      </div>
    );
  };
  
  return (
    <section className="intro-home">
      <div className="intro__bg-wrap">
        <HeaderImage />
        <LogoHeader />
      </div>
      <div className="container intro__container">
        <div className="intro-home__container row h-100 align-items-center pt-5">
          <div className="col-12 col-md-12 col-xl-8">
            {location.pathname === "/" && (
              <Fade bottom>
                <h1 className="title">Experience of sharing living space</h1>
                <LogoHeader />
                <div className="infoContact">
                  <div>www.foxcoliving.be</div>
                  <div><a href="mailto:info@foxcoliving.be">info@foxcoliving.be</a></div>
                  <div><a href="tel:info@foxcoliving.be">+32 2 213 42 02</a></div>
                </div>
              </Fade>
            )}
            {location.pathname == "/fox-brugmann" && (
              <Fade bottom>
                <span className="title title--overhead text-white js-lines">
                  FoxColiving
                </span>
                <h1 className="title title--display-1 js-lines">FoxBrugmann</h1>
              </Fade>
            )}
            {location.pathname == "/fox-latins" && (
              <Fade bottom>
                <span className="title title--overhead text-white js-lines">
                  FoxColiving
                </span>
                <h1 className="title title--display-1 js-lines">FoxLatins</h1>
              </Fade>
            )}
            {location.pathname == "/fox-university" && (
              <Fade bottom>
                <span className="title title--overhead text-white js-lines">
                  FoxColiving
                </span>
                <h1 className="title title--display-1 js-lines">
                  FoxUniversity
                </h1>
              </Fade>
            )}
            {location.pathname == "/fox-washington" && (
              <Fade bottom>
                <span className="title title--overhead text-white js-lines">
                  FoxColiving
                </span>
                <h1 className="title title--display-1 js-lines">
                  FoxWashington
                </h1>
              </Fade>
            )}
            {location.pathname == "/fox-churchill" && (
              <Fade bottom>
                <span className="title title--overhead text-white js-lines">
                  FoxColiving
                </span>
                <h1 className="title title--display-1 js-lines">
                  FoxChurchill
                </h1>
              </Fade>
            )}
            {/* <!-- <button className="intro__btn-play js-lines" id="play">Adventure<span
                                className="btn-play btn-play__popup"></span></button> --> */}
          </div>
        </div>
      </div>
      {/* <!-- Scroll To --> */}
      <HashLink smooth to="/#localisation" className="scroll-to">
        Scroll<span className="scroll-to__line"></span>
      </HashLink>
    </section>
  );
};

export default Header;
