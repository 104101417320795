import React from "react";
import ReactLogo from '../../assets/images/logo-color.svg';

const LogoHeader = () => {
  
  return (
    <>
      <img src={ReactLogo} alt="Fox Coliving Logo" />
    </>
  );
};

export default LogoHeader;
