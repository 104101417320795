import { createSlice } from "@reduxjs/toolkit";
import { firestore, storage } from "../../firebase";

export const roomSlice = createSlice({
  name: "room",
  initialState: {
    rooms: [],
    currentRoom: null,
    bookings: []
  },
  reducers: {
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setCurrentRoom: (state, action) => {
      state.currentRoom = action.payload;
    },
    setRoomsBookings: (state, action) => {
      state.bookings = action.payload;
    },
  },
});

export const { setRooms, setCurrentRoom, setRoomsBookings } = roomSlice.actions;

export const getRooms = () => async (dispatch) => {
  const roomsCollection = await firestore.collection("Rooms").get();
  const rooms = roomsCollection.docs.map((doc) => doc.data());
  for (const room of rooms) {
    room.picture = await storage
      .ref(room.id)
      .child("Header.jpg")
      .getDownloadURL();
  }
  dispatch(setRooms(rooms));
};

export const getRoom = (roomId) => async (dispatch) => {
  const roomItem = await firestore.collection("Rooms").doc(roomId).get();
  const room = roomItem.data();
  room.picture = await storage
    .ref(room.id)
    .child("Header.jpg")
    .getDownloadURL();
  room.pictures = [];
  const images = await storage.ref(room.id).listAll();
  for (const image of images.items) {
    const downloadUrl = await image.getDownloadURL();
    room.pictures.push(downloadUrl);
  }
  dispatch(setCurrentRoom(room));
};

export const getBookings = () => async (dispatch) => {
  const roomsCollection = await firestore.collection("Rooms").get();
  const rooms = roomsCollection.docs.map((doc) => doc.data());
  firestore.collection("Bookings")
    .orderBy("startDate", "desc")
    .onSnapshot((result) => {
      let roomBookings = [];
      rooms.forEach(room => {
        roomBookings.push({
          room : room.id,
          bookings : []
        });
      });
      result.docs.map((doc) => {
        let booking = doc.data();
        let currentRoomBooking = roomBookings.find(x => x.room === booking.room);
        if(currentRoomBooking){
          currentRoomBooking.bookings.push({
            id: doc.id,
            user : booking.user,
            room: booking.room,
            startDate: booking.startDate.toDate().getTime(),
            endDate: booking.endDate.toDate().getTime(),
            status: booking.status,
            price: booking.price
          });
        }
      });
      dispatch(setRoomsBookings(roomBookings));
    });
}

export const roomState = (state) => state.room;

export default roomSlice.reducer;
