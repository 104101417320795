import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRoom } from "../../redux/slices/roomSlice";
import Loader from "react-loader-spinner";
import { useParams } from "react-router-dom";
import "./RoomView.css";
import RoomDetail from "../../components/roomDetail/RoomDetail";

const HomeView = () => {
  let { roomId } = useParams();
  const roomState = useSelector((state) => state.room);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRoom(roomId));
  }, []);
  return (
    <main
      role="main"
      className="flex-shrink-0"
      style={{ paddingBottom: "2rem" }}
    >
      {roomState.currentRoom !== null && <RoomDetail />}
      {roomState.currentRoom === null && (
        <div className="loader">
          <Loader type="ThreeDots" color="#6897BB" height={50} width={50} />
        </div>
      )}
    </main>
  );
};

export default HomeView;
