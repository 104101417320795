import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./RoomListView.scss";
import { setCurrentRoom } from "../../redux/slices/roomSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

const RoomListView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const roomState = useSelector((state) => state.room);
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="main">
      <section className="container section section-first">
        <div className="row align-items-end" style={{ paddingTop: 40 }}>
          <div className="col-12 col-md-12 col-lg-8">
            <span className="title title--overhead js-lines">
              {t("ROOMS_TITLE")}
            </span>
            <h1 className="title title--h1 js-lines">FoxBrugmann</h1>
          </div>
          <div className="col-12 col-md-12 col-lg-4 text-lg-right d-none d-md-block">
            <HashLink to="/fox-brugmann" className="btn-link header-btn-more">
              Read more
            </HashLink>
          </div>
        </div>
        <div className="row">
          {/* <!-- ItemRoom extended --> */}
          {roomState.rooms
            .filter((item) => item.building === "1")
            .map((item, i) => {
              return (
                <div
                  key={item.id}
                  className="col-6 col-lg-4 js-scroll-show"
                  onClick={() => {
                    dispatch(setCurrentRoom(null));
                    history.push("/room/" + item.id);
                  }}
                >
                  <div className="itemRoom itemRoom__extended">
                    <figure className="itemRoom__img-wrap">
                      <a
                        className="itemRoom__link"
                        onClick={() => {
                          dispatch(setCurrentRoom(null));
                          history.push("/room/" + item.id);
                        }}
                      >
                        <img
                          className="cover lazyload"
                          src={item.picture}
                          alt="room"
                        />
                      </a>

                      <div className="itemRoom__details">
                        <h4 className="title title--h4">{item.title_en}</h4>
                        <div className="itemRoom__price">
                          {item.daily_price !== 0 && item.daily_price + "€"}
                          {item.daily_price !== 0 && (
                            <span> {t("ROOM_DAILY_PRICE")}</span>
                          )}
                        </div>
                        <div className="itemRoom__price">
                          {item.monthly_price}€
                          <span> {t("ROOM_MONTHLY_PRICE")}</span>
                        </div>
                      </div>
                    </figure>

                    <div className="itemRoom__details-extended">
                      {/* <div className="item-extended">
                      <i className="icon-map-pin"></i>Adler
                    </div> */}
                      <div className="item-extended mr-4">
                        <i className="icon-maximize"></i>
                        {item.surface} m²
                      </div>
                      <div className="item-extended">
                        <i className="icon-bed"></i>
                        {item.bed}&nbsp;
                        {t("ROOM_BED")}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>

      <section className="container" id="foxlatins">
        <div className="row align-items-end">
          <div className="col-12 col-md-12 col-lg-8">
            <h1 className="title title--h1 js-lines">FoxLatins</h1>
          </div>
          <div className="col-12 col-md-12 col-lg-4 text-lg-right d-none d-md-block">
            <HashLink to="/fox-latins" className="btn-link header-btn-more">
              Read more
            </HashLink>
          </div>
        </div>
        <div className="row">
          {/* <!-- ItemRoom extended --> */}
          {roomState.rooms
            .filter((item) => item.building === "2")
            .map((item, i) => {
              return (
                <div
                  key={item.id}
                  className="col-6 col-lg-4 js-scroll-show"
                  onClick={() => {
                    dispatch(setCurrentRoom(null));
                    history.push("/room/" + item.id);
                  }}
                >
                  <div className="itemRoom itemRoom__extended">
                    <figure className="itemRoom__img-wrap">
                      <a
                        className="itemRoom__link"
                        onClick={() => {
                          dispatch(setCurrentRoom(null));
                          history.push("/room/" + item.id);
                        }}
                      >
                        <img
                          className="cover lazyload"
                          src={item.picture}
                          alt="room"
                        />
                      </a>

                      <div className="itemRoom__details">
                        <h4 className="title title--h4">{item.title_en}</h4>
                        <div className="itemRoom__price">
                          {item.daily_price !== 0 && item.daily_price + "€"}
                          {item.daily_price !== 0 && (
                            <span> {t("ROOM_DAILY_PRICE")}</span>
                          )}
                        </div>
                        <div className="itemRoom__price">
                          {item.monthly_price}€
                          <span> {t("ROOM_MONTHLY_PRICE")}</span>
                        </div>
                      </div>
                    </figure>

                    <div className="itemRoom__details-extended">
                      {/* <div className="item-extended">
                      <i className="icon-map-pin"></i>Adler
                    </div> */}
                      <div className="item-extended mr-4">
                        <i className="icon-maximize"></i>
                        {item.surface} m²
                      </div>
                      <div className="item-extended">
                        <i className="icon-bed"></i>
                        {item.bed}&nbsp;
                        {t("ROOM_BED")}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>

      <section className="container" id="university">
        <div className="row align-items-end">
          <div className="col-12 col-md-12 col-lg-8">
            <h1 className="title title--h1 js-lines">FoxUniversity</h1>
          </div>
          <div className="col-12 col-md-12 col-lg-4 text-lg-right d-none d-md-block">
            <HashLink to="/fox-university" className="btn-link header-btn-more">
              Read more
            </HashLink>
          </div>
        </div>
        <div className="row">
          {/* <!-- ItemRoom extended --> */}
          {roomState.rooms
            .filter((item) => item.building === "3")
            .map((item, i) => {
              return (
                <div
                  key={item.id}
                  className="col-6 col-lg-4 js-scroll-show"
                  onClick={() => {
                    dispatch(setCurrentRoom(null));
                    history.push("/room/" + item.id);
                  }}
                >
                  <div className="itemRoom itemRoom__extended">
                    <figure className="itemRoom__img-wrap">
                      <a
                        className="itemRoom__link"
                        onClick={() => {
                          dispatch(setCurrentRoom(null));
                          history.push("/room/" + item.id);
                        }}
                      >
                        <img
                          className="cover lazyload"
                          src={item.picture}
                          alt="room"
                        />
                      </a>

                      <div className="itemRoom__details">
                        <h4 className="title title--h4">{item.title_en}</h4>
                        <div className="itemRoom__price">
                          {item.daily_price !== 0 && item.daily_price + "€"}
                          {item.daily_price !== 0 && (
                            <span> {t("ROOM_DAILY_PRICE")}</span>
                          )}
                        </div>
                        <div className="itemRoom__price">
                          {item.monthly_price}€
                          <span> {t("ROOM_MONTHLY_PRICE")}</span>
                        </div>
                      </div>
                    </figure>

                    <div className="itemRoom__details-extended">
                      {/* <div className="item-extended">
                      <i className="icon-map-pin"></i>Adler
                    </div> */}
                      <div className="item-extended mr-4">
                        <i className="icon-maximize"></i>
                        {item.surface} m²
                      </div>
                      <div className="item-extended">
                        <i className="icon-bed"></i>
                        {item.bed}&nbsp;
                        {t("ROOM_BED")}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
      
      <section className="container" id="washington">
        <div className="row align-items-end">
          <div className="col-12 col-md-12 col-lg-8">
            <h1 className="title title--h1 js-lines">FoxWashington</h1>
          </div>
          <div className="col-12 col-md-12 col-lg-4 text-lg-right d-none d-md-block">
            <HashLink to="/fox-washington" className="btn-link header-btn-more">
              Read more
            </HashLink>
          </div>
        </div>
        <div className="row">
          {/* <!-- ItemRoom extended --> */}
          {roomState.rooms
            .filter((item) => item.building === "4")
            .map((item, i) => {
              return (
                <div
                  key={item.id}
                  className="col-6 col-lg-4 js-scroll-show"
                  onClick={() => {
                    dispatch(setCurrentRoom(null));
                    history.push("/room/" + item.id);
                  }}
                >
                  <div className="itemRoom itemRoom__extended">
                    <figure className="itemRoom__img-wrap">
                      <a
                        className="itemRoom__link"
                        onClick={() => {
                          dispatch(setCurrentRoom(null));
                          history.push("/room/" + item.id);
                        }}
                      >
                        <img
                          className="cover lazyload"
                          src={item.picture}
                          alt="room"
                        />
                      </a>

                      <div className="itemRoom__details">
                        <h4 className="title title--h4">{item.title_en}</h4>
                        <div className="itemRoom__price">
                          {item.daily_price !== 0 && item.daily_price + "€"}
                          {item.daily_price !== 0 && (
                            <span> {t("ROOM_DAILY_PRICE")}</span>
                          )}
                        </div>
                        <div className="itemRoom__price">
                          {item.monthly_price}€
                          <span> {t("ROOM_MONTHLY_PRICE")}</span>
                        </div>
                      </div>
                    </figure>

                    <div className="itemRoom__details-extended">
                      {/* <div className="item-extended">
                      <i className="icon-map-pin"></i>Adler
                    </div> */}
                      <div className="item-extended mr-4">
                        <i className="icon-maximize"></i>
                        {item.surface} m²
                      </div>
                      <div className="item-extended">
                        <i className="icon-bed"></i>
                        {item.bed}&nbsp;
                        {t("ROOM_BED")}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>

      <section className="container" id="churchill">
        <div className="row align-items-end">
          <div className="col-12 col-md-12 col-lg-8">
            <h1 className="title title--h1 js-lines">FoxChurchill</h1>
          </div>
          <div className="col-12 col-md-12 col-lg-4 text-lg-right d-none d-md-block">
            <HashLink to="/fox-churchill" className="btn-link header-btn-more">
              Read more
            </HashLink>
          </div>
        </div>
        <div className="row">
          {/* <!-- ItemRoom extended --> */}
          {roomState.rooms
            .filter((item) => item.building === "5")
            .map((item, i) => {
              return (
                <div
                  key={item.id}
                  className="col-6 col-lg-4 js-scroll-show"
                  onClick={() => {
                    dispatch(setCurrentRoom(null));
                    history.push("/room/" + item.id);
                  }}
                >
                  <div className="itemRoom itemRoom__extended">
                    <figure className="itemRoom__img-wrap">
                      <a
                        className="itemRoom__link"
                        onClick={() => {
                          dispatch(setCurrentRoom(null));
                          history.push("/room/" + item.id);
                        }}
                      >
                        <img
                          className="cover lazyload"
                          src={item.picture}
                          alt="room"
                        />
                      </a>

                      <div className="itemRoom__details">
                        <h4 className="title title--h4">{item.title_en}</h4>
                        <div className="itemRoom__price">
                          {item.daily_price !== 0 && item.daily_price + "€"}
                          {item.daily_price !== 0 && (
                            <span> {t("ROOM_DAILY_PRICE")}</span>
                          )}
                        </div>
                        <div className="itemRoom__price">
                          {item.monthly_price}€
                          <span> {t("ROOM_MONTHLY_PRICE")}</span>
                        </div>
                      </div>
                    </figure>

                    <div className="itemRoom__details-extended">
                      {/* <div className="item-extended">
                      <i className="icon-map-pin"></i>Adler
                    </div> */}
                      <div className="item-extended mr-4">
                        <i className="icon-maximize"></i>
                        {item.surface} m²
                      </div>
                      <div className="item-extended">
                        <i className="icon-bed"></i>
                        {item.bed}&nbsp;
                        {t("ROOM_BED")}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </main>
  );
};

export default RoomListView;
