import React, { useEffect, Fragment } from "react";
import "./Contact.scss";
import Map from "../../components/map/Map";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="main">
      <section className="container section section-first">
        <div className="row">
          <div className="col-12">
            <h1 className="title title--h1 js-lines">Contact</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 col-contact js-scroll-show">
            <h4 className="title title--h4">Address</h4>
            <p className="paragraph">
              <strong>Fox Brugmann</strong>
              <br />
              Rue Alphonse Renard, 82
              <br />
              1180 Uccle
            </p>
            <p className="paragraph">
              <strong>Fox Latins</strong>
              <br />
              Square des latins, 20
              <br />
              1050 Bruxelles
            </p>
            <p className="paragraph">
              <strong>Fox University</strong>
              <br />
              Avenue de l’université, 94
              <br />
              1050 Bruxelles
            </p>
            <p className="paragraph">
              <strong>Fox Washington</strong>
              <br />
              Rue Washington, 87
              <br />
              1050 Bruxelles
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-contact js-scroll-show">
            <h4 className="title title--h4">Contact</h4>
            <p className="paragraph">
              info@foxcoliving.be
              <br />
              +32 2 213 42 02
              <br />
              +32 2 213 42 04
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-contact js-scroll-show">
            <h4 className="title title--h4">Follow Us</h4>
            {/* <p className="paragraph">Connect with me on&nbsp;</p> */}
            <p className="paragraph">
              <a
                className="link-underline"
                target="_blank"
                href="https://www.instagram.com/fox_coliving/"
              >
                instagram
              </a>
            </p>
            <strong>Fox Latins</strong>
            <p className="paragraph">
              <a
                className="link-underline"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100075766998688"
              >
                facebook
              </a>
            </p>
            <strong>Fox Renard</strong>
            <p className="paragraph">
              <a
                className="link-underline"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100064161152741"
              >
                facebook
              </a>
            </p>
            <strong>Fox Université</strong>
            <p className="paragraph">
              <a
                className="link-underline"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100076418283170"
              >
                facebook
              </a>
            </p>
            <strong>Fox Washington</strong>
            <p className="paragraph">
              <a
                className="link-underline"
                target="_blank"
                href="https://www.facebook.com/Fox-Washington-110774998058221"
              >
                facebook
              </a>
            </p>
          </div>
        </div>
      </section>
      {/* <!-- Map --> */}
      <Map />
    </main>
  );
};

export default Contact;
