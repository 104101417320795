import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./HomeView.css";
import About from "../../components/about/About";
import IconInfo from "../../components/iconInfo/IconInfo";
import Intro from "../../components/intro/Intro";
import IntroImg from "../../components/introImg/IntroImgView";
import ChoiceBuilding from "../../components/choice-building/ChoiceBuilding";
import ContactInfo from "../../components/contactInfo/ContactInfo";
import Map from "../../components/map/Map";

const HomeView = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main role="main" className="flex-shrink-0">
      <Intro />
      {/* <ContactInfo /> */}
      <IntroImg />
      <IconInfo />
      <ChoiceBuilding />
      <About />
      <Map />
      {/* <div className="container">
        <Contact />
      </div> */}
    </main>
  );
};

export default HomeView;
