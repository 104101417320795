import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./slices/globalSlice";
import userReducer from "./slices/userSlice";
import roomReducer from "./slices/roomSlice";

export default configureStore({
  reducer: {
    global: globalReducer,
    user: userReducer,
    room: roomReducer,
  },
});
