import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import "./FoxBrugmann.css";
import Location from "../../components/location/Location";
import RoomList from "../../components/roomList/RoomList";
import Intro from "../../components/intro/Intro";
import ContactInfo from "../../components/contactInfo/ContactInfo";
import SectionIntro from "../../components/sectionIntro/SectionIntro";
import IconInfo from "../../components/iconInfo/IconInfo";
import SectionPlace from "../../components/sectionPlace/SectionPlace";
import Map from "../../components/map/MapSoloPin";

const FoxBrugmann = () => {
  const roomState = useSelector((state) => state.room);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <main role="main">
      <Intro />
      <ContactInfo />
      <SectionIntro />
      <div className="container">
        {roomState.rooms && <RoomList rooms={roomState.rooms}></RoomList>}
        {roomState.rooms.length === 0 && (
          <div className="loader">
            <Loader type="ThreeDots" color="#6897BB" height={50} width={50} />
          </div>
        )}
      </div>
      <SectionPlace />
      {/* <div className="container"> */}
      <Location id="localisation" />
      {/* </div> */}
      <IconInfo />
      <Map />
      {/* <div className="container">
        <Contact />
      </div> */}
    </main>
  );
};

export default FoxBrugmann;
