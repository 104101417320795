import React from "react";
import "./About.css";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import Fade from "@successtar/react-reveal/Fade";

const About = () => {
  const { t } = useTranslation();
  return (
    <section className="container section" id="about">
      <Fade bottom>
        <div className="row cta-box js-scroll-show">
          <div className="col-12 col-lg-7">
            <h2 className="title title--h2">Fox Coliving</h2>
            <h4 className="title title--h4">{t("ABOUT_US_TITLE")}</h4>
            <p className="paragraph">{t("ABOUT_US_CONTENT_1")}</p>
            <p className="paragraph">{t("ABOUT_US_CONTENT_2")}</p>
            <p className="paragraph">{t("ABOUT_US_CONTENT_3")}</p>
          </div>
          <div className="col-12 col-lg-5 text-lg-right">
            <LinkContainer to="/roomlist">
              <a className="btn btn__large btn--white">
                {t("ABOUT_US_BUTTON")}{" "}
                <i className="btn-icon-right icon-arrow-special"></i>
              </a>
            </LinkContainer>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default About;
