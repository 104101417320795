import React from "react";
import "./IntroImg.scss";
import { useSelector } from "react-redux";
import headerDesktop from "../../assets/images/header-desktop.jpg";
import headerMobile from "../../assets/images/header-mobile.jpg";
import Fade from "@successtar/react-reveal/Fade";

const IntroImg = () => {
  const isMobile = useSelector((state) => state.user.isMobile);
  
  return (
    <>
      <Fade bottom>
        {isMobile ? 
          <div className="introImgHeader">
            <img
              className="ls-is-cached lazyloaded my-5"
              src={headerMobile}
              alt=""
            />
          </div> : 
          <div className="introImgHeader">
            <img
              className="ls-is-cached lazyloaded my-5"
              src={headerDesktop}
              alt=""
            />
          </div>}
      </Fade>
    </>
  );
};

export default IntroImg;
