import React, { Fragment } from "react";
import "./ChoiceBuilding.scss";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import img1 from "../../assets/images/brugmann/image_slider_home_01.jpg";
import img2 from "../../assets/images/latins/LATINS_COMMUNS-AVEC-VUE-TERRASSE.jpg";
import img3 from "../../assets/images/university/SALLE_A_MANGER_COMMUN.jpg";
import img4 from "../../assets/images/washington/washington_commun.jpg";
import img5 from "../../assets/images/churchill/community_room.jpg";
import Fade from "@successtar/react-reveal/Fade";
import { HashLink } from "react-router-hash-link";

const ChoiceBuilding = () => {
  const { t } = useTranslation();

  return (
    <section className="container section" id="localisation">
      <Fade bottom>
        <div className="row align-items-end">
          <div className="col-12 col-md-12 col-lg-8">
            <span className="title title--overhead js-lines">
              Make your choice
            </span>
            <h1 className="title title--h1 js-lines">Fox House</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4">
            <HashLink to="/fox-brugmann">
              <div className="itemPerson">
                <figure className="itemPerson__img-wrap">
                  <img
                    className="cover ls-is-cached lazyloaded"
                    src={img1}
                    alt=""
                  />
                </figure>
                <div className="itemPerson__details">
                  <h4 className="title title--h4">FoxBrugmann</h4>
                  <span className="title title--overhead-small">
                    Rue Alphonse Renard 82 - 1180 Uccle
                  </span>
                </div>
              </div>
            </HashLink>
          </div>
          <div className="col-6 col-md-4">
            <HashLink to="/fox-latins">
              <div className="itemPerson">
                <figure className="itemPerson__img-wrap">
                  <img
                    className="cover ls-is-cached lazyloaded"
                    src={img2}
                    alt=""
                  />
                </figure>
                <div className="itemPerson__details">
                  <h4 className="title title--h4">FoxLatins</h4>
                  <span className="title title--overhead-small">
                    Square des latins 20 - 1050 Bruxelles
                  </span>
                </div>
              </div>
            </HashLink>
          </div>
          <div className="col-6 col-md-4">
            <HashLink to="/fox-university">
              <div className="itemPerson">
                <figure className="itemPerson__img-wrap">
                  <img
                    className="cover ls-is-cached lazyloaded"
                    src={img3}
                    alt=""
                  />
                </figure>
                <div className="itemPerson__details">
                  <h4 className="title title--h4">FoxUniversity</h4>
                  <span className="title title--overhead-small">
                    Avenue de l’université 94 - 1050 Bruxelles
                  </span>
                </div>
              </div>
            </HashLink>
          </div>
          <div className="col-6 col-md-4">
            <HashLink to="/fox-washington">
              <div className="itemPerson">
                <figure className="itemPerson__img-wrap">
                  <img
                    className="cover ls-is-cached lazyloaded"
                    src={img4}
                    alt=""
                  />
                </figure>
                <div className="itemPerson__details">
                  <h4 className="title title--h4">FoxWashington</h4>
                  <span className="title title--overhead-small">
                    Rue washington 87 - 1050 Bruxelles
                  </span>
                </div>
              </div>
            </HashLink>
          </div>
          <div className="col-6 col-md-4">
            <HashLink to="/fox-churchill">
            <div className="itemPerson">
              <figure className="itemPerson__img-wrap">
                <img
                    className="cover ls-is-cached lazyloaded"
                    src={img5}
                    alt=""
                  />
              </figure>
              <div className="itemPerson__details">
                <h4 className="title title--h4">FoxChurchill</h4>
                <span className="title title--overhead-small">
                  Avenue Winston Churchill 7 1180 BXL 
                </span>
              </div>
            </div>
            </HashLink>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default ChoiceBuilding;
