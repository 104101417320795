import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./AuthenticationView.css";
import Login from "../../components/login/Login";
import Registration from "../../components/registration/Registration";
import ForgottenPassword from "../../components/forgottenPassword/ForgottenPassword";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const AuthenticationView = () => {
  const { t } = useTranslation();
  const globalState = useSelector((state) => state.global);
  const [currentStep, setCurrentStep] = useState("login");
  return (
    <Modal show={globalState.showAuthenticationPopup} onHide={() => {}}>
      <Modal.Header>
        <ButtonGroup style={{width: "100%"}}>
          <Button variant="outline-dark" style={{width: "33%"}} onClick={() => {
            setCurrentStep("login");
          }}>{t("AUTH_LOGIN")}</Button>
          <Button variant="outline-dark" style={{width: "33%"}} onClick={() => {
            setCurrentStep("registration");
          }}>{t("AUTH_REGISTRATION")}</Button>
          <Button variant="outline-dark" style={{width: "33%"}} onClick={() => {
            setCurrentStep("forgottenPassword");
          }}>{t("AUTH_FORGOTTEN_PASSWORD")}</Button>
        </ButtonGroup>
      </Modal.Header>
      <Modal.Body>
        {currentStep === "login" && <Login />}
        {currentStep === "registration" && <Registration />}
        {currentStep === "forgottenPassword" && <ForgottenPassword />}
      </Modal.Body>
    </Modal>
  );
};

export default AuthenticationView;
