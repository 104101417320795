import React, { useState, useRef } from "react";
import "./SectionIntro.scss";
import { useTranslation } from "react-i18next";
import Fade from "@successtar/react-reveal/Fade";
import Swiper from "react-id-swiper";
import { useLocation } from "react-router-dom";

const SectionIntro = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
 
  return (
    <section className="section" id="place">
      <div className="container">
        {location.pathname == "/fox-latins" && (
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 mb-5">
              <iframe
                width="853"
                height="480"
                src="https://my.matterport.com/show/?m=H9tRDaKFBbr"
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
                style={{ height: "450px" }}
              ></iframe>
            </div>
          </div>
        )}
        {location.pathname == "/fox-university" && (
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 mb-5">
              <iframe
                width="853"
                height="480"
                src="https://my.matterport.com/show/?m=Ywo3WprVK5v"
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
                style={{ height: "450px" }}
              ></iframe>
            </div>
          </div>
        )}
        {location.pathname == "/fox-washington" && (
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 mb-5">
              <iframe
                width="853"
                height="480"
                src="https://my.matterport.com/show/?m=HGLFZVp9otQ"
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
                style={{ height: "450px" }}
              ></iframe>
            </div>
          </div>
        )}
        <div className="row">
          <Fade bottom>
            <div className="col-12 col-lg-5">
              <span className="title title--overhead js-lines">the place</span>
              {location.pathname == "/fox-brugmann" && (
                <h1 className="title title--h1 js-lines">FoxBrugmann</h1>
              )}
              {location.pathname == "/fox-latins" && (
                <h1 className="title title--h1 js-lines">FoxLatins</h1>
              )}
              {location.pathname == "/fox-university" && (
                <h1 className="title title--h1 js-lines">FoxUniversity</h1>
              )}
              {location.pathname == "/fox-washington" && (
                <h1 className="title title--h1 js-lines">FoxWashington</h1>
              )}
              {location.pathname == "/fox-churchill" && (
                <h1 className="title title--h1 js-lines">FoxChurchill</h1>
              )}
            </div>
          </Fade>
          <Fade bottom>
            {location.pathname == "/fox-brugmann" && (
              <div className="col-12 col-lg-6 offset-lg-1 offset-top">
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_01")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_02")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_03")}
                </p>
              </div>
            )}
          </Fade>
          <Fade bottom>
            {location.pathname == "/fox-latins" && (
              <div className="col-12 col-lg-6 offset-lg-1 offset-top">
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_LATINS_01")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_LATINS_02")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_LATINS_03")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_LATINS_04")}
                </p>
              </div>
            )}
          </Fade>
          <Fade bottom>
            {location.pathname == "/fox-university" && (
              <div className="col-12 col-lg-6 offset-lg-1 offset-top">
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_UNIVERSITY_01")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_UNIVERSITY_02")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_UNIVERSITY_03")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_UNIVERSITY_04")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_UNIVERSITY_05")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_UNIVERSITY_06")}
                </p>
              </div>
            )}
          </Fade>
          <Fade bottom>
            {location.pathname == "/fox-washington" && (
              <div className="col-12 col-lg-6 offset-lg-1 offset-top">
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_WASHINGTON_01")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_WASHINGTON_02")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_WASHINGTON_03")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_WASHINGTON_04")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_WASHINGTON_05")}
                </p>
              </div>
            )}
          </Fade>
          <Fade bottom>
            {location.pathname == "/fox-churchill" && (
              <div className="col-12 col-lg-6 offset-lg-1 offset-top">
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_CHURCHILL_01")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_CHURCHILL_02")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_CHURCHILL_03")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_CHURCHILL_04")}
                </p>
                <p className="paragraph js-scroll-show">
                  {t("SECTIONPLACE_INFO_CHURCHILL_05")}
                </p>
              </div>
            )}
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default SectionIntro;
