import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import HomeView from "../views/home/HomeView";
import RoomView from "../views/room/RoomView";
import RoomListView from "../views/roomListView/RoomListView";
import DashboardView from "../views/dashboard/DashboardView";
import Contact from "../views/contact/Contact";
import FoxBrugmann from "../views/foxBrugmann/FoxBrugmann";
import FoxLatins from "../views/foxLatins/FoxLatins";
import FoxUniversity from "../views/foxUniversity/FoxUniversity";
import FoxWashington from "../views/foxWashington/FoxWashington";
import FoxChurchill from "../views/foxChurchill/FoxChurchill";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomeView} />
      <Route path="/dashboard" component={DashboardView} />
      <Route path="/room/:roomId" component={RoomView} />
      <Route path="/roomList" component={RoomListView} />
      <Route path="/contact" component={Contact} />
      <Route path="/fox-brugmann" component={FoxBrugmann} />
      <Route path="/fox-latins" component={FoxLatins} />
      <Route path="/fox-university" component={FoxUniversity} />
      <Route path="/fox-washington" component={FoxWashington} />
      <Route path="/fox-churchill" component={FoxChurchill} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default Routes;
