import React, { Fragment } from "react";
import "./RoomList.css";
import { useTranslation } from "react-i18next";
import RoomCard from "../roomCard/RoomCard";
import { useHistory, useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Fade from "@successtar/react-reveal/Fade";
import { HashLink } from "react-router-hash-link";

const RoomList = ({ rooms }) => {
  const { t } = useTranslation();
  const location = useLocation();
  
  return (
    <section className="container section" id="roomlist">
      <Fade bottom>
        <div className="row align-items-end">
          <div className="col-12 col-md-12 col-lg-8">
            <span className="title title--overhead js-lines">Rooms</span>
            <h1 className="title title--h1 js-lines">{t("ROOMS_TITLE")}</h1>
          </div>
          <div className="col-12 col-md-12 col-lg-4 text-lg-right d-none d-md-block">
            <HashLink smooth to="/roomlist">
              <a className="btn-link header-btn-more">{t("ROOM_MORE_INFO")}</a>
            </HashLink>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        {/* <!-- Grid --> */}
        {location.pathname == "/fox-brugmann" && (
          <div className="row">
            {rooms
              .filter((item) => item.building === "1")
              .map((item) => (
                <RoomCard room={item} key={item.id}></RoomCard>
              ))}
          </div>
        )}
      </Fade>
      <Fade bottom>
        {location.pathname == "/fox-latins" && (
          <div className="row">
            {rooms
              .filter((item) => item.building === "2")
              .map((item) => (
                <RoomCard room={item} key={item.id}></RoomCard>
              ))}
          </div>
        )}

        {/* <div className="row">
          {rooms.map((item, i) => {
            return <RoomCard room={item} key={item.id}></RoomCard>;
          })}
        </div> */}
      </Fade>
      <Fade bottom>
        {/* <!-- Grid --> */}
        {location.pathname == "/fox-university" && (
          <div className="row">
            {rooms
              .filter((item) => item.building === "3")
              .map((item) => (
                <RoomCard room={item} key={item.id}></RoomCard>
              ))}
          </div>
        )}
      </Fade>
      <Fade bottom>
        {/* <!-- Grid --> */}
        {location.pathname == "/fox-washington" && (
          <div className="row">
            {rooms
              .filter((item) => item.building === "4")
              .map((item) => (
                <RoomCard room={item} key={item.id}></RoomCard>
              ))}
          </div>
        )}
      </Fade>
      <Fade bottom>
        {/* <!-- Grid --> */}
        {location.pathname == "/fox-churchill" && (
          <div className="row">
            {rooms
              .filter((item) => item.building === "5")
              .map((item) => (
                <RoomCard room={item} key={item.id}></RoomCard>
              ))}
          </div>
        )}
      </Fade>
    </section>
  );
};

export default RoomList;
