import React, {useState, useEffect} from "react";
import "./Header.scss";
import { useTranslation } from "react-i18next";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import logoBlack from '../../assets/images/logo-black-2.svg';
import logoWhite from '../../assets/images/logo-black-1.svg';
import { useRouteMatch, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const [hamburger, setHamburger] = useState(false);
  const { t, i18n } = useTranslation();

  const location = useLocation();
  let matchRoomlist = useRouteMatch("/roomlist");
  let matchContact = useRouteMatch("/contact");
  let matchDashboard = useRouteMatch("/dashboard");
  let matchFoxLatins = useRouteMatch("/foxLatins");
  
  const toggleMenu = () => {
    setHamburger(!hamburger);
    hamburger
    ? document.body.classList.remove("open-nav")
    : document.body.classList.add("open-nav")
  };

  return (
    <header
      className={`header ${
        matchRoomlist || matchContact || matchDashboard ? "alternate" : ""
      }`}
    >
      <nav className="navbar navbar-white navbar-overlay" id="navbar">
        <LinkContainer to="/">
          <a className="logo-link">
            <img
              className="logotype black"
              src={logoBlack}
              alt="FoxBrugmann"
            ></img>
            <img
              className="logotype white"
              src={logoWhite}
              alt="FoxBrugmann"
            ></img>
          </a>
        </LinkContainer>
        <div className="navbar__menu">
          <button
            className={`hamburger ${hamburger ? "is-active" : ""}`}
            type="button"
            onClick={() => toggleMenu()}
          >
            <span></span>
            <span></span>
          </button>
          <ul className={`nav ${hamburger ? "is-open" : ""}`}>
            <NavDropdown title={t("MENU_ROOMS")} id="collasible-nav-dropdown">
              <NavDropdown.Item href="/fox-brugmann">
                FoxBrugmann
              </NavDropdown.Item>
              <NavDropdown.Item href="/fox-latins">FoxLatins</NavDropdown.Item>
              <NavDropdown.Item href="/fox-university">
                FoxUniversity
              </NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item href="/fox-washington">
                FoxWashington
              </NavDropdown.Item>
              <NavDropdown.Item href="/fox-churchill">
                FoxChurchill
              </NavDropdown.Item>
            </NavDropdown>

            <li className="nav__item hideDesktop">
              <LinkContainer to="/fox-brugmann" onClick={toggleMenu}>
                <a className="nav__link">
                  <span data-hover="FoxBrugmann">FoxBrugmann</span>
                </a>
              </LinkContainer>
            </li>
            <li className="nav__item hideDesktop">
              <LinkContainer to="/fox-latins" onClick={toggleMenu}>
                <a className="nav__link">
                  <span data-hover="FoxLatins">FoxLatins</span>
                </a>
              </LinkContainer>
            </li>
            <li className="nav__item hideDesktop">
              <LinkContainer to="/fox-university" onClick={toggleMenu}>
                <a className="nav__link">
                  <span data-hover="FoxUniversity">FoxUniversity</span>
                </a>
              </LinkContainer>
            </li>
            <li className="nav__item hideDesktop">
              <LinkContainer to="/fox-washington" onClick={toggleMenu}>
                <a className="nav__link">
                  <span data-hover="FoxWashington">FoxWashington</span>
                </a>
              </LinkContainer>
            </li>
            <li className="nav__item hideDesktop">
              <LinkContainer to="/fox-churchill" onClick={toggleMenu}>
                <a className="nav__link">
                  <span data-hover="FoxChurchill">FoxChurchill</span>
                </a>
              </LinkContainer>
            </li>
            <li className="nav__item">
              <HashLink
                smooth
                to="/#about"
                className="nav__link"
                onClick={toggleMenu}
              >
                <span data-hover="About us">About us</span>
              </HashLink>
            </li>
            <li className="nav__item">
              <LinkContainer to="/contact" onClick={toggleMenu}>
                <a className="nav__link">
                  <span data-hover={t("MENU_CONTACT_US")}>
                    {t("MENU_CONTACT_US")}
                  </span>
                </a>
              </LinkContainer>
            </li>
            <li className="nav__item hideDesktop">
              <LinkContainer to="/dashboard" onClick={toggleMenu}>
                <Button className="btn btn__medium" variant="outline-success">
                  <i className="btn-icon-left icon-bookmark"></i>
                  {t("MENU_ACCOUNT")}
                </Button>
              </LinkContainer>
            </li>
            {/* <li className="nav__item hideDesktop">
              <NavDropdown.Item
                className="mobileLanguage"
                onClick={() => {
                  i18n.changeLanguage("fr");
                  toggleMenu()
                }}
              >
                FR
              </NavDropdown.Item>
              <NavDropdown.Item
                className="mobileLanguage"
                onClick={() => {
                  i18n.changeLanguage("en");
                  toggleMenu();
                }}
              >
                EN
              </NavDropdown.Item>
            </li> */}
          </ul>
        </div>
        <div className="navbar__btn">
          <LinkContainer to="/dashboard" onClick={toggleMenu}>
            <Button className="btn btn__medium" variant="outline-success">
              {t("MENU_ACCOUNT")}
            </Button>
          </LinkContainer>
          {/* <NavDropdown
            title={i18n.language.toUpperCase()}
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item
              onClick={() => {
                i18n.changeLanguage("fr");
              }}
            >
              FR
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => {
                i18n.changeLanguage("en");
              }}
            >
              EN
            </NavDropdown.Item>
          </NavDropdown> */}
        </div>
      </nav>
    </header>
    /* <!-- /Header --> */
  );
};

export default Header;
