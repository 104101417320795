export const formatDate = (time) => {
    var date = new Date(time);
    const day =
        date.getDate().toString().length === 1
            ? "0" + date.getDate().toString()
            : date.getDate().toString();
    const month =
        (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1).toString()
            : (date.getMonth() + 1).toString();
    const year =
        date.getFullYear().toString().length === 1
            ? "0" + date.getFullYear().toString()
            : date.getFullYear().toString();
    const hour =
        date.getHours().toString().length === 1
            ? "0" + date.getHours().toString()
            : date.getHours().toString();
    const minutes =
        date.getMinutes().toString().length === 1
            ? "0" + date.getMinutes().toString()
            : date.getMinutes().toString();
    return day + "-" + month + "-" + year + " " + hour + ":" + minutes;
};

export const findInArray = (array, attr, value) => {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
};