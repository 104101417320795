import { auth } from "../firebase";
import axios from 'axios';

export const sendMail = async (room, startDate, endDate, price, mailType, language) => {
    var url = "https://sendmail-3qb34bvzlq-uc.a.run.app";
    var date_start = new Date(startDate);
    var date_end = new Date(endDate);
    var startDay = date_start.getDate().toString().length === 1 ? "0" + date_start.getDate().toString() : date_start.getDate().toString();
    var startMonth = (date_start.getMonth() + 1).toString().length === 1 ? "0" + (date_start.getMonth() + 1).toString() : (date_start.getMonth() + 1).toString();
    var startYear = date_start.getFullYear().toString();
    var startHours = date_start.getHours().toString().length === 1 ? "0" + date_start.getHours().toString() : date_start.getHours().toString();
    var startMinutes = date_start.getMinutes().toString().length === 1 ? "0" + date_start.getMinutes().toString() : date_start.getMinutes().toString();
    var endDay = date_end.getDate().toString().length === 1 ? "0" + date_end.getDate().toString() : date_end.getDate().toString();
    var endMonth = (date_end.getMonth() + 1).toString().length === 1 ? "0" + (date_end.getMonth() + 1).toString() : (date_end.getMonth() + 1).toString();
    var endYear = date_end.getFullYear().toString();
    var endHours = date_end.getHours().toString().length === 1 ? "0" + date_end.getHours().toString() : date_end.getHours().toString();
    var endMinutes = date_end.getMinutes().toString().length === 1 ? "0" + date_end.getMinutes().toString() : date_end.getMinutes().toString();
    axios.post(url, {
        "key": "83a34466-56e3-442d-9252-25016bc88ff2",
        "room": room,
        "startDate": startDay + "/" + startMonth + "/" + startYear + " " + startHours + ":" + startMinutes,
        "endDate": endDay + "/" + endMonth + "/" + endYear + " " + endHours + ":" + endMinutes,
        "price": price.toString(),
        "mailType": mailType,
        "language": language,
        "mailTo": auth.currentUser.email
    })
    .then(res => {
      console.log(res.data);
    })
    .catch((error) => console.log(error));
};