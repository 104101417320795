import React, { useRef, useEffect, useState } from "react";
import "./Map.scss";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Pin from "../../assets/icons/placeholder.svg";
import { HashLink } from "react-router-hash-link";

const TOKEN =
  "pk.eyJ1IjoiYmlvbWFuaWFjayIsImEiOiJja2dyMDZ6N3YwOWFuMnhtZnZpanVoZHdhIn0.FXRFlQ2eIA4CzwHZu8pA1w";

const Map = () => {
  const [viewport, setViewPort] = useState({
    width: "fit",
    height: "100%",
    latitude: 50.8160666,
    longitude: 4.359065,
    zoom: 13,
  });

  const _onViewportChange = (viewport) =>
    setViewPort({ ...viewport, transitionDuration: 300 });

  return (
    <div id="map" className="map-bottom" name="firstInsideContainer">
      <ReactMapGL
        className="mapContainer"
        {...viewport}
        mapboxApiAccessToken={TOKEN}
        mapStyle="mapbox://styles/mapbox/light-v10"
        onViewportChange={_onViewportChange}
      >
        {/* <GeolocateControl
          style={geolocateStyle}
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={true}
        /> */}
        <div style={{ position: "absolute", right: 0 }}>
          <NavigationControl />
        </div>

        <Marker
          latitude={50.8160666}
          longitude={4.359065}
          offsetLeft={-20}
          offsetTop={-10}
        >
          <HashLink to="/fox-brugmann">
            <img src={Pin} style={{ width: "50px" }}></img>
          </HashLink>
        </Marker>
        <Marker
          latitude={50.8137462}
          longitude={4.3851896}
          offsetLeft={-20}
          offsetTop={-10}
        >
          <HashLink to="/fox-latins">
            <img src={Pin} style={{ width: "50px" }}></img>
          </HashLink>
        </Marker>
        <Marker
          latitude={50.8151828}
          longitude={4.3862857}
          offsetLeft={-20}
          offsetTop={-10}
        >
          <HashLink to="/fox-university">
            <img src={Pin} style={{ width: "50px" }}></img>
          </HashLink>
        </Marker>
        <Marker
          latitude={50.8225579}
          longitude={4.3629071}
          offsetLeft={-20}
          offsetTop={-10}
        >
          <HashLink to="/fox-washington">
            <img src={Pin} style={{ width: "50px" }}></img>
          </HashLink>
        </Marker>
        <Marker
          latitude={50.8117514}
          longitude={4.3654363}
          offsetLeft={-20}
          offsetTop={-10}
        >
          <HashLink to="/fox-churchill">
            <img src={Pin} style={{ width: "50px" }}></img>
          </HashLink>
        </Marker>
      </ReactMapGL>
      )
    </div>
  );
};

export default Map;
