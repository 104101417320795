import React from "react";
import "./IconInfo.scss";
import { useTranslation } from "react-i18next";
import Fade from "@successtar/react-reveal/Fade";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className="section-icon section-gray">
      <Fade bottom>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-4 col-md-4 col-lg-2">
              <i className="icon-fox-meeting-point"></i>
              <p className="text-center">
                {t("SECTIONICON_COMMUNITY_SPACE")}
              </p>
            </div>
            <div className="col-4 col-md-4 col-lg-2">
              <i className="icon-fox-icon-wifi"></i>
              <p className="text-center">
                {t("SECTIONICON_FREE_WIFI")}
              </p>
            </div>
            <div className="col-4 col-md-4 col-lg-2">
              <i className="icon-fox-washing-machine"></i>
              <p className="text-center">
                {t("SECTIONICON_LANDRY_ROOM")}
              </p>
            </div>
            <div className="col-4 col-md-4 col-lg-2">
              <i className="icon-fox-leaf"></i>
              <p className="text-center">{t("SECTIONICON_GARDEN")}</p>
            </div>
            <div className="col-4 col-md-4 col-lg-2">
              <i className="icon-fox-bike"></i>
              <p className="text-center">
                {t("SECTIONICON_BIKE_PARKING")}
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default Contact;
