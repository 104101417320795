import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { auth } from "./firebase";
import { setUser, getUser, setMobileStatus } from "./redux/slices/userSlice";
import { getRooms } from "./redux/slices/roomSlice";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import history from "./services/history";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import AuthenticationView from "./views/authentication/AuthenticationView";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRooms());
console.log('test');
    const checkMobileStatus = () => {
      if (window.innerWidth <= 768) {
        dispatch(setMobileStatus(true));
      } else {
        dispatch(setMobileStatus(false));
      }
    };

    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(getUser());
      } else {
        dispatch(setUser(null));
      }
    });

    checkMobileStatus();

    window.addEventListener("resize", checkMobileStatus);

    return () => {
      window.removeEventListener("resize", checkMobileStatus);
    };
  }, [dispatch]);

  return (
    <Router history={history}>
      <Header />
      <AuthenticationView />
      <Routes />
      <Footer />
    </Router>
  );
}

export default App;
