import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import "./RoomDetail.css";
import Booking from "../booking/Booking";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import Swiper from "react-id-swiper";

const RoomCard = () => {
  const roomState = useSelector((state) => state.room);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };


  const swiperParams = {
    loop: true,
    speed: 900,
    slidesPerView: "auto",
    spaceBetween: 140,
    centeredSlides: true,
    grabCursor: true,
    mousewheel: true,
    breakpoints: {
      0: {
        spaceBetween: 0,
      },
      580: {
        spaceBetween: 40,
      },
      768: {
        spaceBetween: 40,
      },
      990: {
        spaceBetween: 80,
      },
      1200: {
        spaceBetween: 80,
      },
      1500: {
        spaceBetween: 100,
      },
    },
  };
  //END SWIPER
  
  //PARALLAX
  const calc = (o) => `translateY(${o * 0.1}px)`;

  const HeaderImage = () => {
    const ref = useRef();
    const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

    const handleScroll = () => {
      const posY = ref.current.getBoundingClientRect().top;
      const offset = window.pageYOffset - posY;
      set({ offset });
    };

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });

    return (
      <div ref={ref} className="overlay intro__bg js-image">
        <animated.div
          style={{
            backgroundImage: `url(${roomState.currentRoom.picture})`,
            backgroundSize: "cover",
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            transform: offset.interpolate(calc),
          }}
        />
      </div>
    );
  };

  return (
    <main className="main">
      <section className="intro">
        <div className="intro__bg-wrap">
          <HeaderImage />
        </div>
        <div className="container intro__container">
          <div className="row h-100 align-items-center align-items-center justify-content-center">
            <div className="col-12 col-xl-8 text-center">
              <span className="title title--overhead text-white js-lines">
                {i18n.language === "fr" && roomState.currentRoom.subtitle_fr}
                {i18n.language === "en" && roomState.currentRoom.subtitle_en}
              </span>
              <h1 className="title title--display-1 js-lines">
                {i18n.language === "fr" && roomState.currentRoom.title_fr}
                {i18n.language === "en" && roomState.currentRoom.title_en}
              </h1>
            </div>
          </div>
        </div>
        {/* <!-- Scroll To --> */}
        <a className="scroll-to" href="!#content">
          Scroll<span className="scroll-to__line"></span>
        </a>
      </section>
      {/* <!-- Room base info --> */}
      <div className="bottom-panel bottom-panelRoom">
        <div className="bottom-panel__wrap">
          <div className="row h-100 align-items-center">
            <div className="col-12 col-md-12 col-xl-8">
              <div className="row room-details">
                <div className="col-4 room-details__item slash">
                  <i className="icon-maximize"></i>
                  {roomState.currentRoom.surface} m²
                </div>
                <div className="col-4 room-details__item slash">
                  <i className="icon-bed"></i>
                  {roomState.currentRoom.bed}&nbsp;
                  {t("ROOM_BED")}
                </div>
                <div className="col-4 room-details__item">
                  {roomState.currentRoom.shower && (
                    <div className="bath-shower">
                      <img
                        className="icon icon--24"
                        src={require("../../assets/icons/icon-shower.svg")}
                        alt=""
                      />
                      {t("ROOM_SHOWER")}{" "}
                    </div>
                  )}
                  {roomState.currentRoom.shower &&
                    roomState.currentRoom.bath && <span>&nbsp;/&nbsp;</span>}
                  {roomState.currentRoom.bath && (
                    <div className="bath-shower">
                      <img
                        className="icon icon--24"
                        src={require("../../assets/icons/icon-bath.svg")}
                        alt=""
                      />
                      {t("ROOM_BATH")}{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Content --> */}
      <section id="content" className="container">
        <div className="row sticky-parent">
          <div className="col-md-12 col-xl-8 order-2 order-xl-1 mt-4 mt-sm-5">
            {/* <!-- Description room --> */}
            <h3 className="title title--h3">Description</h3>
            <p>
              {i18n.language === "fr" && roomState.currentRoom.desc_fr}
              {i18n.language === "en" && roomState.currentRoom.desc_en}
            </p>

            <p className="font-italic text-info">{t("SUBDETAIL_INFO_ROOM")}</p>

            {/* <!-- Amenity --> */}
            <h3 className="title title--h3 mt-4 mt-sm-5">Confort</h3>
            <div className="row">
              <ul className="list-unstyled list-feature col-6 col-md-4">
                {roomState.currentRoom.wifi && (
                  <li className="list-feature__item">
                    <img
                      className="icon icon--24"
                      src={require("../../assets/icons/icon-wifi.svg")}
                      alt=""
                    />
                    {t("ROOM_WIFI")}
                  </li>
                )}
                {roomState.currentRoom.safe && (
                  <li className="list-feature__item">
                    <img
                      className="icon icon--24"
                      src={require("../../assets/icons/icon-safebox.svg")}
                      alt=""
                    />
                    {t("ROOM_SAFE")}
                  </li>
                )}
                {roomState.currentRoom.desk && (
                  <li className="list-feature__item">
                    <img
                      className="icon icon--24"
                      src={require("../../assets/icons/icon-desk.svg")}
                      alt=""
                    />
                    {t("ROOM_DESK")}
                  </li>
                )}
                {roomState.currentRoom.kitchen && (
                  <li className="list-feature__item">
                    <img
                      className="icon icon--24"
                      src={require("../../assets/icons/icon-kitchen.svg")}
                      alt=""
                    />
                    {t("ROOM_KITCHEN")}
                  </li>
                )}
                {roomState.currentRoom.washbasin && (
                  <li className="list-feature__item">
                    <img
                      className="icon icon--24"
                      src={require("../../assets/icons/icon-basin.svg")}
                      alt=""
                    />
                    {t("ROOM_BASIN")}
                  </li>
                )}
                {roomState.currentRoom.toilet && (
                  <li className="list-feature__item">
                    <img
                      className="icon icon--24"
                      src={require("../../assets/icons/icon-toilet.svg")}
                      alt=""
                    />
                    {t("ROOM_TOILET")}
                  </li>
                )}
                {roomState.currentRoom.balcony && (
                  <li className="list-feature__item">
                    <img
                      className="icon icon--24"
                      src={require("../../assets/icons/icon-balcony.svg")}
                      alt=""
                    />
                    {t("ROOM_BALCONY")}
                  </li>
                )}
                {roomState.currentRoom.soda_stream && (
                  <li className="list-feature__item">
                    <img
                      className="icon icon--24"
                      src={require("../../assets/icons/un-soda.png")}
                      alt=""
                    />
                    {t("ROOM_SODA_STREAM")}
                  </li>
                )}
                {roomState.currentRoom.coffee_machine && (
                  <li className="list-feature__item">
                    <img
                      className="icon icon--24"
                      src={require("../../assets/icons/cafetiere.png")}
                      alt=""
                    />
                    {t("ROOM_COFFEE_MACHINE")}
                  </li>
                )}
              </ul>
            </div>

            {/* <!-- Gallery slider --> */}
            <div className="slider-room ltr swiper-container mt-3">
              <div className="swiper-wrapper">
                <Swiper {...swiperParams} ref={swiperRef}>
                  {roomState.currentRoom.pictures.map((picture) => {
                    return (
                      <div key={picture}>
                        <img alt="poster" width="100%" src={picture} />
                      </div>
                    );
                  })}
                </Swiper>
              </div>

              {/* <!-- Navigation --> */}
              <div className="swiper-control swiper-control--bottom">
                <div className="slash">
                  <div
                    className="swiper-button-next swiper-button-square"
                    onClick={goPrev}
                  >
                    <i className="icon-chevron-left"></i>
                  </div>
                  <div
                    className="swiper-button-prev swiper-button-square"
                    onClick={goNext}
                  >
                    <i className="icon-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /Gallery slider --> */}
          </div>

          {/* <!-- Sidebar Booking --> */}
          <Booking />
          {/* <!-- /Sidebar Booking --> */}
        </div>
      </section>
      {/* <!-- /Content --> */}
      {/* <!-- /Room base info --> */}
    </main>
  );
};

export default RoomCard;
