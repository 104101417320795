import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { auth } from "../../firebase";
import { setShowAuthenticationPopup } from "../../redux/slices/globalSlice";
import { logout } from "../../redux/slices/userSlice";
import Loader from "react-loader-spinner";
import "./DashboardView.css";
import { useHistory } from "react-router-dom";
import { setUser, getUser } from "../../redux/slices/userSlice";
import Button from "react-bootstrap/Button";
import BookingList from "../../components/bookingList/BookingList";
import Admin from "../../components/admin/Admin";

const DashboardView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const [view, setView] = useState("bookings");
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(setShowAuthenticationPopup(false));
        dispatch(getUser());
      } else {
        dispatch(setShowAuthenticationPopup(true));
        dispatch(setUser(null));
      }
    });
  }, []);
  return (
    <main role="main" className="main">
      <section className="section-first">
        <div className="container">
          {!userState.user && (
            <div className="loader">
              <Loader type="ThreeDots" color="#6897BB" height={50} width={50} />
            </div>
          )}
          {userState.user && (
            <div>
              <div className="row">
                <div className="col-sm">
                  <h1>
                    {t("DASHBOARD_TITLE")} {userState.user.firstName}
                  </h1>
                </div>
                <div className="col-sm">
                  <Button
                    className="float-right"
                    style={{ width: "150px" }}
                    variant="outline-secondary"
                    type="button"
                    onClick={() => {
                      history.push("/");
                      dispatch(logout());
                      dispatch(setShowAuthenticationPopup(false));
                    }}
                  >
                    {t("DASHBOARD_LOGOUT")}
                  </Button>
                  {userState.user.admin && (
                    <div>
                      <Button
                        className="float-right"
                        style={{ width: "150px", marginRight: "10px" }}
                        variant="outline-secondary"
                        type="button"
                        onClick={() => {
                          setView("admin");
                        }}
                      >
                        {t("DASHBOARD_ADMIN")}
                      </Button>
                      <Button
                        className="float-right"
                        style={{ width: "150px", marginRight: "10px" }}
                        variant="outline-secondary"
                        type="button"
                        onClick={() => {
                          setView("bookings");
                        }}
                      >
                        {t("DASHBOARD_BOOKINGS")}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              {view === "bookings" && <BookingList />}
              {view === "admin" && <Admin />}
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default DashboardView;
