import React from "react";
import "./RoomCard.css";
import { setCurrentRoom } from "../../redux/slices/roomSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RoomCard = ({ room }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { i18n } = useTranslation();
  return (
    <div className="col-6 col-md-4">
      {/* <!-- ItemRoom --> */}
      <div className="itemRoom">
        <figure className="itemRoom__img-wrap">
          <a
            className="itemRoom__link"
            onClick={() => {
              dispatch(setCurrentRoom(null));
              history.push("/room/" + room.id);
            }}
          >
            <img className="cover" src={room.picture} alt="room" />
          </a>
        </figure>
        <div
          className="itemRoom__details"
          onClick={() => {
            dispatch(setCurrentRoom(null));
            history.push("/room/" + room.id);
          }}
        >
          {i18n.language === "fr" && (
            <h4 className="title title--h4">{room.title_fr}</h4>
          )}
          {i18n.language === "en" && (
            <h4 className="title title--h4">{room.title_en}</h4>
          )}
          <div className="itemRoom__price">
            {i18n.language === "fr" && (
              <span>{room.subtitle_fr}</span>
            )}
            {i18n.language === "en" && (
              <span>{room.subtitle_en}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
